<!--
 * @Description: 新装回访统计报表
 * @Author: LiangYiNing
 * @Date: 2022-05-25 11:01:47
 * @LastEditTime: 2022-07-15 16:04:47
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="companyId">
              <e6-vr-select
                v-model="searchForm.companyId"
                :data="companyEnum"
                placeholder="所属主体"
                title="所属主体"
                :props="{
                  id: 'companyId',
                  label: 'companyFullName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="attrIds">
              <e6-vr-select
                v-model="searchForm.attrIds"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpClass">
              <e6-vr-select
                v-model="searchForm.corpClass"
                :data="corpClassEnum"
                placeholder="客户分级"
                title="客户分级"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="employeeId">
              <all-user-search
                v-model="searchForm.employeeId"
                clear
                placeholder="业务员"
                title="业务员"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
                :showSlot="true"
              >
              </all-user-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaId">
              <e6-select
                v-model="searchForm.areaIdList"
                :dataList="regionalEnum"
                placeholder="大区"
                title="大区"
                :filterable="true"
                :slotTemplate="'tree'"
                :multiple="false"
                :issingleleaf="true"
                @multipleSelect="areaIdListChange"
                ref="t_select"
              ></e6-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="officeId">
              <e6-select
                v-model="searchForm.officeIdList"
                :dataList="frameworkTreeEnum"
                placeholder="办事处"
                title="办事处"
                :filterable="true"
                :slotTemplate="'tree'"
                :multiple="false"
                :issingleleaf="true"
                @multipleSelect="officeIdListChange"
                ref="t_select"
              ></e6-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="revisitTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="回访时间"
                ref="revisitTime"
                type="datetime"
                v-model="searchForm.revisitTime"
                title="回访时间"
                :picker-options="pickerOptions('searchForm.revisitTime')"
                start-placeholder="回访时间(始)"
                end-placeholder="回访时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            :show-overflow-tooltip="column.fieldName !== 'attrName'"
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="column.fieldName === 'attrName'"
                class="item"
                effect="dark"
                :content="row.attrName"
                placement="right"
              >
                <div class="ellipsis">
                  {{ row.attrName }}
                </div>
              </el-tooltip>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import allUserSearch from "@/components/allUserSearch";
import {
  findDownList,
  firstInstallReturnVisitStatisticsReportList,
  firstInstallReturnVisitStatisticsReportExport,
  getCorpAttrDownList,
  getCompanydownList,
  getFrameworkTree
} from "@/api";
import {
  printError,
  getMothRangeTimestamp,
  getRadioSelectTreeEnum
} from "@/utils/util";
import { exportData } from "@/utils/download";

// 按钮ID常量
import setting from "@/setting.js";
let { EXPORT_BUTTON_ID } = setting;

export default {
  name: "FirstInstallReturnVisitStatisticsReport",
  data() {
    return {
      TrensferFunsApplyVisible: false,
      total: 0,
      timePickerResetList: ["revisitTime"], // base mixin的配置项 需要重置的timePicker refname
      corpAttrEnum: [], // 客户属性枚举
      companyEnum: [], // 所属主体枚举
      corpList: [], // 客户列表枚举
      corpClassEnum: [], // 客户分级枚举
      regionalEnum: [], // 大区枚举
      itemStatusEnum: [],
      frameworkTreeEnum: [], // 办事处枚举(三级组织架构)
      EXPORT_BUTTON_ID,
      queryListAPI: firstInstallReturnVisitStatisticsReportList,
      searchForm: {
        corpId: "", // 客户ID
        companyId: "", // 主体ID
        attrIds: [], // 客户属性id集合
        corpClass: "", // 客户分级枚举值
        employeeId: "", // 员工ID
        areaId: "", // 大区ID
        officeId: "", // 组织架构ID
        officeIdList: [], // 组织架构ID
        revisitTime: getMothRangeTimestamp(), // 创建时间
        revisitTimeBeginTimeStamp: "", // 开始时间
        revisitTimeEndTimeStamp: "", // 结束时间
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "createdTime", // 默认创建时间排序
        sortDir: "descending" // 默认降序
      },
      columnData: [
        {
          fieldName: "revisitId",
          fieldLabel: "回访ID",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companySimName",
          fieldLabel: "主体简称",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          fieldLabel: "客户名称",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "employeeName",
          fieldLabel: "业务员",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          fieldLabel: "大区",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "officeName",
          fieldLabel: "办事处",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpClassStr",
          fieldLabel: "客户分级",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "attrName",
          display: true,
          fieldLabel: "客户属性",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "visitMan",
          fieldLabel: "回访人",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitTime",
          fieldLabel: "回访日期",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitedManName",
          fieldLabel: "被回访人",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneNum",
          fieldLabel: "联系方式",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "quesName",
          fieldLabel: "问题",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "answerName",
          fieldLabel: "答案",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          fieldLabel: "备注",
          width: 160,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: {
    allUserSearch
  },
  watch: {
    // 创建开始时间
    "searchForm.revisitTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.revisitTime = this.searchForm.revisitTime.reverse();
        }
        this.searchForm.revisitTimeBeginTimeStamp = val[0] || "";
        this.searchForm.revisitTimeEndTimeStamp = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
    this.initData();
    this.loadCorpData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 获取枚举信息
    async initData() {
      try {
        let enumKey = ["itemStatus", "corpClass"];
        //获取枚举数据
        let promiseList = [
          findDownList(enumKey),
          getCorpAttrDownList({}),
          getCompanydownList({ isManageEmp: false, companyId: "" }),
          getFrameworkTree()
        ];
        let [
          findDownListRes,
          corpAttrRes,

          companyEnumRes,
          frameworkTreeRes
        ] = await Promise.all(promiseList);
        enumKey.map(item => {
          this[`${item}Enum`] = this.getFreezeResponse(findDownListRes, {
            path: `data.${item}`
          });
        });
        this.corpAttrEnum = this.getFreezeResponse(corpAttrRes, {
          path: "data"
        });

        //所属主体
        this.companyEnum = this.getFreezeResponse(companyEnumRes, {
          path: "data"
        });
        let frameworkTreeData = this.getFreezeResponse(frameworkTreeRes, {
          path: "data"
        });
        // 获取单选第三级组织架构数据
        this.frameworkTreeEnum = getRadioSelectTreeEnum([frameworkTreeData]);
        // 大区选组织架构二级
        this.regionalEnum = getRadioSelectTreeEnum([frameworkTreeData], 2);
      } catch (error) {
        printError(error);
      }
    },
    // 办事处 取id值到对应字段上
    officeIdListChange(val) {
      if (val && val.length) {
        this.searchForm.officeId = val[0].id;
      } else {
        this.searchForm.officeId = "";
      }
    },
    // 大区 取id值到对应字段上
    areaIdListChange(val) {
      if (val && val.length) {
        this.searchForm.areaId = val[0].id;
      } else {
        this.searchForm.areaId = "";
      }
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPropId;
      });
    },
    // 导出
    handleExport() {
      exportData(this, firstInstallReturnVisitStatisticsReportExport);
    }
  }
};
</script>
<style lang="scss" scoped></style>
